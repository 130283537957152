<template>
  <!-- Contacto -->
  <div class="bg-contact" id="contact">
    <div class="container py-5">
      <div class="container">
        <div class="card">
          <h2 class=" text-center text-primary-blue position-relative title-home mb-5">Contacto</h2>
          <div class="d-block d-md-flex mt-5 p-4">
            <div class="col-md-6 p-3 d-flex">
              <iframe class="mt-1"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.374401931232!2d-70.65362948462517!3d-33.43955090433938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5a40a09277f%3A0x2ddff52811be337a!2sHu%C3%A9rfanos%201055%2C%20Oficina%20503%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1626763096951!5m2!1ses-419!2scl"
                width="100%" style="border: 0" allowfullscreen="" loading="lazy"
              ></iframe>
            </div>
            <div class="col-md-6 p-3" v-show="!formsuccess && !formerror">
              <v-form class="mt-1" v-model="form" ref="form">
                <div class="row px-3">
                  <v-text-field
                    prepend-icon="mdi-account-circle"
                    v-model="formdata.name"
                    label="Nombre"
                    :rules="[rules.required]"
                    filled
                  />
                  <v-text-field
                    class="form-floating"
                    prepend-icon="mdi-phone"
                    v-model="formdata.phone"
                    label="Teléfono"
                    :rules="[rules.required]"
                    filled
                  />
                  <v-text-field
                    class="form-floating"
                    prepend-icon="mdi-email"
                    v-model="formdata.email"
                    label="Correo"
                    :rules="[rules.email]"
                    filled
                  />
                  <v-textarea
                    class="form-floating"
                    prepend-icon="mdi-message"
                    v-model="formdata.message"
                    label="Mensaje"
                    :rules="[rules.required, rules.length(2000)]"
                    filled
                    clearable
                  />
                  <p class="my-4">*Datos confidenciales.</p>
                  <v-btn
                    color="primary" 
                    type="submit"
                    @click.prevent="submit"
                    block
                    :disabled="!form"
                    :loading="formloading"
                  >
                    Enviar
                  </v-btn>
                </div>
              </v-form>
            </div>
            <div class="col-md-6 p-3" v-show="formsuccess && !formerror">
              <div class="row px-3 mt-1">
                <div class="card p-5 p-md-3 p-xl-5 profile"
                     style="pointer-events:none">
                  <h4 class="text-center pt-4 pb-2">
                      Gracias por el contacto
                  </h4>
                  <p class="text-center">
                    Hemos recibido tu mensaje y 
                    <br/>
                    responderemos a la brevedad
                  </p>
                  <v-container fill-height fluid>
                    <v-row align="center"
                        justify="center">
                        <v-img
                          src="@/assets/dev/riverineblackwhite.png"
                          height="50%"
                          max-width="50%"
                          justify="center"
                        />
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
            <div class="col-md-6 p-3" v-show="formerror">
              <div class="row px-3 mt-1">
                <div class="card p-5 p-md-3 p-xl-5 profile"
                     style="pointer-events:none">
                  <h4 class="text-center pt-4 pb-2">
                      Error en envío de mensaje
                  </h4>
                  <p class="text-center">
                    Hubo un problema en la recepción del mensaje
                    <br/>
                    Intente de nuevo más tarde
                  </p>
                  <v-container fill-height fluid>
                    <v-row align="center"
                        justify="center">
                        <v-img
                          src="@/assets/dev/riverineblackwhite.png"
                          height="50%"
                          max-width="50%"
                          justify="center"
                        />
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

  import ApiService from '@/common/api.service'

  export default {
    data () {
      return {
        show1: false,
        error: false,
        rules: {
          required: (value) => !!value || 'Required',
          length: len => v => (v || '').length < len || `Invalid character length, maximum ${len}`,
          email: v => !v ||/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Please enter a valid email',
          min: (v) => v.length >= 8 || 'Min 8 characters',
        },
        form: false,
        formdata:{
            name:'',
            phone:'',
            email:'',
            message:'',
        },
        formsuccess: false,
        formloading: false,
        formerror  : false,
      }
    },
    methods: {
      submit () {

        // AS GET
        this.formloading=true;
        ApiService.getWithParams('contact/', this.formdata ).then((response) => {
            if ( response.data=='' ) {
                this.formsuccess = true;
                this.formloading = false;
            } else {
                this.formsuccess = false;
                this.formloading = false;
                this.form        = false
            }
        }).catch( error => {
            this.formerror   = true;
            this.formsuccess = false;
            this.formloading = false;
        });

      },
    },

  }

</script>

<style>

</style>
